import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      fullWidth
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              {...field}
              fullWidth
              format="DD-MMM-YYYY"
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
              slotProps={{
                textField: {
                  helperText: error?.message,
                },
              }}
              {...other}
            />
          </DemoContainer>
        </LocalizationProvider>
        // <TextField
        //   {...field}
        //   fullWidth
        //   value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
        //   error={!!error}
        //   helperText={error?.message}
        //   {...other}
        // />
      )}
    />
  );
}
