import { useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// //
// import Blog from './pages/Blog';
// import User from './pages/User';
// import Login from './pages/Login';
// import NotFound from './pages/Page404';
// import Register from './pages/Register';
// import Products from './pages/Products';
// import DashboardApp from './pages/DashboardApp';
import MarriagePredictionsApp from './pages/MarriagePredictionsApp';
import PredictionsResultsApp from './pages/PredictionsResultsApp';
import Page404 from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // {
    //   path: '/dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: 'app', element: <DashboardApp /> },
    //     { path: 'user', element: <User /> },
    //     { path: 'products', element: <Products /> },
    //     { path: 'blog', element: <Blog /> },
    //   ],
    // },
    // {
    //   path: 'login',
    //   element: <Login />,
    // },
    // {
    //   path: 'register',
    //   element: <Register />,
    // },
    {
      path: '/',
      element: <MarriagePredictionsApp />,
    },
    {
      path: '/predictions',
      element: <PredictionsResultsApp />,
    },

    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '/', element: <Navigate to="/dashboard/app" /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);
}
