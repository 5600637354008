import { faker } from '@faker-js/faker';
// @mui

import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

// sections
import { AppOrderTimeline } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function PredictionsResultsApp() {
  return (
    <Page title="Predictions">
      <Container maxWidth="xl">
        <br />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppOrderTimeline
              title="Marriage Predictions"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  'இரு மனம் இணைந்து ஒருமனதாக திருமணம் ஆகும் இனிய தருணம்.',
                  'நீ என்ற சொல்லில் அவள் என்பதை பொருளாக்கி வாழ்ந்திடு அவள் என்ற பொருளில் நீ என்பதை சொல்லாக்கி வாழ்ந்திடு. இதை விட ஆனந்தம் யுகத்திலில்லை.',
                  'பத்துப் பொருத்தங்களைப் பார்த்து, ஒன்பது கோள் நிலைகளை அறிந்து, எட்டுத்திசையிலிருந்தும் உறவை அழைத்து, ஏழு அடி எடுத்து வைத்து, அறுசுவை உணவு படைத்து, பஞ்ச பூதங்கள் சாட்சியாக, நான்கு வேதங்கள் முழங்க, மூன்று முடிச்சுகளால், இரு மனங்கள் ஒன்று சேரும், ஓர் அற்புத பந்தத்தின் உறவே, திருமணம்..!',
                  'கருத்தொருமித்த தம்பதியராய்... சுற்றம் வியக்கும் வாழ்வை காண்பீர்.. உதாரணத் தம்பதியராய்... ஊர் போற்ற உறவும் போற்ற... இணைபிரியாத வாழ்வினிலே.. நூறாண்டு காலம் வாழ்ந்திடவே... உளம் கனிந்த நல்லாழ்த்துக்கள்.. திருமண நாள் நல்வாழ்த்துகள்..!',
                  'ஊரே வியக்கும் வண்ணம், சிறந்த அன்பு கொண்ட நேசங்களாகி, திருமண வாழ்க்கையின் அர்த்தத்தை உணர்ந்து வாழும் தம்பதியராக, இல்லறத்தில் புரிதல் உணர்வுடன் இரு நெஞ்சங்களும் சுற்றத்தாரின் வாழ்த்துகளோடு நூறாண்டு காலம் வாழ்ந்திட இந்த இனிய திருமண நாள் வாழ்த்துக்கள்..!',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
