import * as Yup from 'yup';
// import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
// import Iconify from '../../../components/Iconify';
import dayjs from 'dayjs';
import { FormProvider, RHFTextField, RHFDatePicker } from '../../../components/hook-form';
// ----------------------------------------------------------------------

export default function AstrologyForm() {
  const navigate = useNavigate();

  // const [showPassword, setShowPassword] = useState(false);

  const AstrologySchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    dateofbirth: Yup.string().required('Date of birth required'),
    placeofbirth: Yup.string().required('Place of birth required'),
  });
  const tomorrow = dayjs().add(1, 'day');
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    dateofbirth: tomorrow,
    placeofbirth: '',
  };

  const methods = useForm({
    resolver: yupResolver(AstrologySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    navigate('/predictions', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>

        <RHFTextField name="email" label="Email address" />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFDatePicker name="dateofbirth" label="Date of birth" />
        </Stack>
        <RHFTextField name="placeofbirth" label="Place of birth" />
        {/* <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Continue
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
